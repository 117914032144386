// # Applied colors palette #
//============================================
// ## Main colors ##
$color-primary: #464545;
$color-secondary: #737272;
$color-primary-background: #FFFFFF;
$color-secondary-background: #000000;
$color-golden-accent: #866D4B;
$color-promo-background: #F0F0F0;
$color-success: #1A862C;
$color-error: #CA1E36;
$color-error-background: #faeded;

// ## Shades ##
$color-shade_1: $color-secondary-background;
$color-shade_2: $color-primary;
$color-shade_3: $color-secondary;
$color-shade_4: #C3C1C1;
$color-shade_5: $color-promo-background;
$color-shade_6: $color-primary-background;

// ## Badges ##
$color-badge_2-color: $color-secondary;

// ## GH/GF colors ##
$color-header_text: $color-primary-background;
$color-header_alt: $color-primary-background;
$color-footer_text: $color-primary-background;
$color-footer_alt: $color-primary-background;

// ## Footer ##
$color-footer-border: $color-shade_4;
$color-footer-background: $color-secondary-background;

// ## Header ##
$color-header-background: $color-secondary-background;
$color-header__transparent-text: $color-header_text;
$color-header__transparent__inverted-text: $color-header_alt;
$color-header_promo-background: $color-primary;
$color-header__transparent-header_promo-background: $color-promo-background;
$color-navigation_flyout-background : $color-primary-background;
$color-hamburger_menu-background: $color-primary-background;
$color-hamburger_back_link-background: $color-primary-background;
$color-hamburger_account-background: $color-primary-background;
$color-main_nav-divider: $color-golden-accent;

// ## Buttons ##
// ### Button Main ###
$color-button_main-background: $color-golden-accent;
$color-button_main-border: $color-golden-accent;
$color-button_main-text: $color-primary-background;

$color-button_main__hover-background: $color-primary-background;
$color-button_main__hover-border: $color-golden-accent;
$color-button_main__hover-text: $color-secondary-background;

$color-button_main__inversed-background: $color-primary-background;
$color-button_main__inversed-border: $color-golden-accent;
$color-button_main__inversed-text: $color-secondary-background;

$color-button_main__inversed__hover-background: $color-golden-accent;
$color-button_main__inversed__hover-border: $color-golden-accent;
$color-button_main__inversed__hover-text: $color-primary-background;

// ### Alt ###
$color-button_alt-background: $color-primary-background;
$color-button_alt-border: $color-golden-accent;
$color-button_alt-text: $color-secondary-background;
$color-button_alt__hover-background: $color-golden-accent;
$color-button_alt__hover-border: $color-golden-accent;
$color-button_alt__hover-text: $color-primary-background;
$color-button_alt__inversed-background: $color-golden-accent;
$color-button_alt__inversed-border: $color-golden-accent;
$color-button_alt__inversed-text: $color-primary-background;
$color-button_alt__inversed__hover-background: $color-primary-background;
$color-button_alt__inversed__hover-border: $color-golden-accent;
$color-button_alt__inversed__hover-text: $color-secondary-background;

// Semantic colors mapping to components
// # COMMON #
$color-text__dark: $color-primary-background;
$color-text__light: $color-primary;

// # COMPONENTS #
$color-product_image-background: $color-primary-background;

// # PDP #
$color-product_gallery-thumb-border:transparent;
$color-product_gallery-thumb__selected-border:$color-error;

// # PLP # Refinement #
$color-refinements-border_color: $color-shade_4;
$color-refinements-text: $color-secondary-background;
$color-sorting-label: $color-secondary-background;
$color-filters-label: $color-secondary;
$color-filters-border: $color-secondary;

// ## MESSAGE ##
$color-message-text__inversed: $color-primary;

// ## Product tile ##
$color-product_tile-promo-background: $color-promo-background;

// ## NOTIFICATION ##
$color-notification-text: $color-primary;

// ## TABS ##
$color-tabs-border: $color-shade_4;
$color-tabs-text: $color-secondary;
$color-tabs-text__active: $color-secondary-background;

// # ERROR PAGES #
$color-error_410-text: $color-text__light;

// # PAGE DESIGNER #
$color-banner_2_region: $color-primary;

// ## PRODUCT WISHLIST CTA ##
$color-wishlist: $color-golden-accent;
$color-wishlist-active: $color-golden-accent;

// ## FORMS ##
$color-form-input: $color-secondary-background;
$color-form-input-background: $color-primary-background;
$color-form-input-border: $color-secondary;
$color-form-input__disabled: $color-shade_4;
$color-form-label: $color-primary;

// ## PRODUCT QUEANTITY STEPPER ##
$color-quantity: $color-secondary;
$color-quantity-background: $color-primary-background;

$color-svg-icon: $color-golden-accent;

// ## PRODUCT AVAILABILITY  ##
$color-product_availability-in_stock: $color-success;
$color-product_availability-low_stock: $color-success;
$color-product_availability-out_of_stock: $color-error;

// ## SWATCHES ##
$color-swatch_border: $color-shade_4 ;
$color-swatch_border-selected: $color-secondary-background;
$color-swatch_title:$color-secondary;

// ## ACCOUNT NAVIGATION ##
$color-account_nav-item__active-bg: $color-shade_4;

// # MY ACCOUNT #
$color-account_info_tile-bg: $color-primary-background;

// # STORE LOCATOR #
$color-store_locator-background: $color-primary-background;
$color-store_locator-close_text: $color-primary;
$color-store_locator-nav_background: $color-primary-background;
$color-store_locator-border: $color-primary;

// ## MESSAGE ##
$color-message-background: $color-primary-background;
$color-message-border: $color-primary;
$color-message-text: $color-primary;
$color-message-text__inversed: $color-primary;
$color-message-background__inversed: rgba($color-primary-background, 0.15) ;

// ## LINKS ##
$color-link: $color-primary;
$color-link__visited: $color-secondary;

// ## PROGRESS BAR ##
$color-progress-bg: $color-primary-background;
$color-progress-value-bg: $color-golden-accent;

// ## BREADCRUMBS ##
$color-breadcrumb-current: $color-secondary;
$color-breadcrumb-separator_bg: $color-golden-accent;
$color-breadcrumb-link_color:  $color-primary;

// ## PRODUCT TABLE ###
$color-product_table-head_text: $color-primary;
$color-product_table-sku: $color-primary;

// ## ORDER SUMMARY ##
$color-order_summary-border: $color-shade_4;
$color-order_summary-separator: $color-shade_4;

// ## CHECKOUT ##
$color-checkout-gift_message_label: $color-primary;

// ## CAROUSEL ##
$color-carousel_control:$color-golden-accent;
$color-carousel_pagination_control: $color-primary-background;
$color-carousel_pagination_control__active: $color-golden-accent;

// # AGE GATE #
$color-age_gate-text: $color-primary-background;
$color-age_gate-background: $color-secondary-background;

// ## BACK TO TOP ##
$color-back_to_top-icon-background: $color-primary-background;

// ## Menu Item
$color-divider: $color-shade_4;

// ## Age Gate
$color-age_gate-bg: #101820;
$color-age_gate-too-young: #db1414;
$color-age_gate-description: #8d8d8d;
$color-age_gate-border-one:#866d4b80;
$color-age_gate-border-two: rgba(255, 255, 255, 0.5);

