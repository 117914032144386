// # Header
// ## Logo
$configuration-logo-width: 356px;
$configuration-logo-width__md_down: 120px;
$configuration-logo__stuck-width: 208px;
$configuration-logo__stuck-width__md_down: $configuration-logo-width__md_down;
$configuration-logo__portrait-width: 52px;
$configuration-logo__stuck__portrait-width: 52px;
// ### Logo aspect ratio variables. They have to be an integer
$configuration-logo-image-height: 104;
$configuration-logo-image-width: 356;
$configuration-logo-image__portrait-height: 150;
$configuration-logo-image__portrait-width: 109;
$configuration-logo__stuck-image__portrait-height: 130;// reduce the height to hide the bottom of a logo

// # Buttons
$configuration-button_main-border_width: 3px;
$configuration-button_main_border_radius: 0px;
$configuration-button_main__disabled-opacity:1;
$configuration-button_alt_border_radius:0px;

// # Links
$configuration-link_2-underline_offset: 0;

// # Form
$configuration-form-input-font: 700 16px/22px $font-main;
$configuration-form-select-font: $configuration-form-input-font;
$size-input-height : 56px;
$configuration-global_label-font: 400 16px/24px $font-main;

// # Product tile
$configuration-product_tile-cta_type: alt;
$configuration-product_tile-description-font_weight: normal;
$configuration-product_tile-description-letter_spacing: 0.2px;
$configuration-product_tile-name-font: 700 20px $font-alt ;
$configuration-product_tile-name-font_size__small: 16px;
$configuration-product_tile-name-letter_spacing: 1px;
$configuration-product_tile-name-transform: uppercase;

// # Product badge
$configuration-product_badge-points-line_height: 0.8;
$configuration-product_badge-border_radius: 0;
$configuration-product_badge-font: 700 12px/24px $font-main;
$configuration-product_badge-font_size__small: 9px;

// # Promo
$configuration-promo-border_radius: 0px;

// # Product quantity stepper
$configuration-product_quantity-border_width: 2px;

// # PLP & SEARCH
$configuration-filter_selected-border_radius: 3px;
$configuration-noof-grid-template-columns:3;

// # Age gate
$configuration-age_gate-background_image: none;
$configuration-age_gate__mobile-background_image: none;
$configuration-age_gate-button_type: main;
$configuration-age_gate-button_modification: default;

// # Checkout
// ## Gift message
$color-checkout-gift_message_label: $color-primary;;

// ## Confirmation page
$color-checkout_confirmation-account-copy: $color-primary-background;
$configuration-confirmation-account-background_image: url('../images/age-gate-background.jpg');
$configuration-confirmation-account-background_image__small: url('../images/age-gate-background.jpg');
$configuration-confirmation_create_account-button_modifications: default;

// # Error pages
$color-error_404-text: $color-primary-background;
$color-error_page-background: $color-secondary-background;
$configuration-error_page__404-background_image:  url('../images/age-gate-background.jpg');
$configuration-error_page__404__mobile-background_image: url('../images/age-gate-background.jpg');

// # Cart product details
$configuration-cart_product_details-name-font:700 16px/24px $font-alt;
$configuration-cart_product_details-name-letter_spacing:1px !default;
$configuration-cart_product_details-name-transform: uppercase !default;

// # Headings
// ## Headings ## //
$configuration-t_heading_1-font: 400 42px/64px $font-alt;
$configuration-t_heading_1-2-font: 400 42px/64px $font-alt;
$configuration-t_heading_2-font: 400 36px/48px $font-alt;
$configuration-t_heading_3-font: 400 24px/40px $font-alt;
$configuration-t_heading_4-font: 400 22px/32px $font-main !default;
$configuration-t_heading_5-font: 400 20px/32px $font-alt !default;
$configuration-t_heading_6-font: 700 18px/24px $font-main !default;

// # Minicart product
$configuration-minicart_product-name-font_size: 22px;

// ## Global header Navigation
$configuration-category_navigation-list_1-padding: 16px 0 0;
$configuration-category_navigation-link-font: 400 20px/32px $font-alt;
$configuration-category_navigation-link-letter_spacing: 1px;
$configuration-category_navigation-link-text_tranform: uppercase;


$configuration-global_label-text_transform: capitalize;

$configuration-button-font_size: 16px;
$configuration-button-letter_spacing: 0;

//Product Tile
$configuration-product_tile-name-font: 22px;

//Button
$configuration-button-font_size: 18px;