// Globals
$global-radius: 0px;
$global-line: 1px;
$global-content-width-min: 310px; // minimum supported with. Info: $viewport: 320px - $verticalScroll: ~10px. https://www.w3.org/WAI/WCAG21/Understanding/reflow.html
$global-content-width-max: 1560px + 80px * 2;
$global-content-width-max-lg: 1280px + 60px * 2;
$global-content-width-max-narrow: 1148px; // used on MA and CO
$global-site-width-max: 1920px; // used on Header and HP fullbleed promo

// Z-depth
$depth-0: none;
$depth-1: 0 2px 4px rgba($color-box-shadow, 0.08), 0 4px 5px rgba($color-box-shadow, 0.11);
$depth-1-1: 0 2px 4px rgba($color-box-shadow, 0.08); // separate shadow for drop-shadow function
$depth-1-2: 0 4px 5px rgba($color-box-shadow, 0.11);
$depth-2: 0 3px 10px hsla(0, 0%, 0%, 0.35);
$depth-2-no-top: 0 10px 10px rgba(0, 0, 0, 0.35);
$depth-3: 0 3px 15px hsla(0, 0%, 0%, 0.35);

// Motion
$motion-fast: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.2s;
$motion-ease: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.4s;
$motion-ease-panels: cubic-bezier(0.3, 0.46, 0.45, 0.94) 0.5s;

//New Motion
$motion-regular: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
